import React, { lazy } from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';

const AddJob = lazy(() => import('../../pages/jobs/AddJob'));
const AddUser = lazy(() => import('../../container/pages/AddUsers'));
const JobsList = lazy(() => import('../../pages/jobs/index'));
const Calendar = lazy(() => import('../../pages/calendar/index'));

const PagesRoute = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route path={`${path}/add-job`} component={AddJob} />
      <Route path={`${path}/edit-user/:id`} component={AddUser} />
      <Route path={`${path}/list`} component={JobsList} />
      <Route path={`${path}`} component={Calendar} />
    </Switch>
  );
};

export default PagesRoute;
