import React, { lazy, Suspense } from 'react';
import { Spin } from 'antd';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import Dashboard from './dashboard';
import Users from './users';
import Jobs from './jobs';
import Projects from './projects';
import Customers from './customer';
import System from './system';
import withAdminLayout from '../../layout/withAdminLayout';

import { useDispatch ,useSelector} from 'react-redux';
const TimeReport = lazy(() => import('../../pages/timereport/Report'));
const ClientReport = lazy(() => import('../../pages/clientreport/Report'));
const ClientDataReport = lazy(() => import('../../pages/client_report/Report'));


const SignReport = lazy(() => import('../../pages/reportsign/Report'));

const Calendar = lazy(() => import('../../container/calendar/Calendar'));
const Kanban = lazy(() => import('../../container/kanban/Index'));
const Appointment = lazy(() => import('../../pages/appointment/Appointment'));
const CalendarT = lazy(() => import('../../container/calendar/Calendar'));
const Periods = lazy(() => import('../../pages/periods'));
const Periodsclient = lazy(() => import('../../pages/periodsclient'));
const AppointmentList = lazy(() => import('../../pages/appointments'));
const Gannt  = lazy(() => import('../../pages/gannt'));
const Fechamento  = lazy(() => import('../../pages/fechamentocliente'));
const Holidays  = lazy(() => import('../../pages/holidays'));
const Appointments_list  = lazy(() => import('../../pages/appointments_list'));
const PerformanceProjetos = lazy(() => import('../../container/dashboardprojects/Performance'));
const Expenses = lazy(() => import('../../pages/expenses'));




const Admin = () => {
  const { path } = useRouteMatch();
  
  const {  isLoggedIn,user_id ,user_category} = useSelector(state => {
    return {
      user_category: state.auth.user_category,
      user_id: state.auth.user_id,
    };
  });

  return (
    <Switch>
      <Suspense
        fallback={ <div className="spin">
                      <Spin />
                   </div>
        }
      >
        <Route path={`${path}/gannt`} component={Gannt} />
        <Route path={`${path}/appointment`} exact component={Appointment} />
        <Route path={`${path}/appointment/:apo_id`} exact component={Appointment} />
        <Route path={`${path}/appointments`} component={AppointmentList} />
        <Route path={`${path}/appointments_list`} component={Appointments_list} />
        <Route path={`${path}/timereport`} component={TimeReport} />
        <Route path={`${path}/clientreport`} component={ClientReport} />
        <Route path={`${path}/client_report`} component={ClientDataReport} />
        <Route path={`${path}/report/:appointment_id`} component={SignReport} />
        <Route path={`${path}/fechamento`} component={Fechamento} />

        
        
        <Route path={`${path}/feriados`} component={Holidays} />
        
        <Route path={`${path}/users`} component={Users} />
        <Route path={`${path}/jobs`} component={Jobs} />
        <Route path={`${path}/projects`} component={Projects} />
        <Route path={`${path}/periods`} component={Periods} />
        <Route path={`${path}/periodsclient`} component={Periodsclient} />
        <Route path={`${path}/customers`} component={Customers} />
        <Route path={`${path}/system`} component={System} />

        
        <Route path={`${path}/calendar`} component={CalendarT} />
        <Route path={`${path}/app/kanban`} component={Kanban} />
        <Route path={`${path}/app/calendar`} component={Calendar} />
        <Route path={`${path}/dash`} component={Dashboard} />
        <Route path={`${path}/dashprojeto`} component={PerformanceProjetos} />
        
        <Route path={`${path}/expenses`} component={Expenses} />
        
        
      </Suspense>
    </Switch>
  );
};

export default withAdminLayout(Admin);
