import React, { lazy } from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';

const Systemconfig = lazy(() => import('../../pages/system/Systemconfig'));
const ContactGrid = lazy(() => import('../../pages/system/ContactGrid'));

const PagesRoute = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route path={`${path}`} component={Systemconfig} />
    </Switch>
  );
};

export default PagesRoute;
