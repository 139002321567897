import React, { lazy } from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';

const AddUser = lazy(() => import('../../container/pages/AddUsers'));
const ContactGrid = lazy(() => import('../../pages/analista/ContactGrid'));

const PagesRoute = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route path={`${path}/add-user`} component={AddUser} />
      <Route path={`${path}/edit-user/:id`} component={AddUser} />
      <Route path={`${path}`} component={ContactGrid} />
    </Switch>
  );
};

export default PagesRoute;
