
import axios from 'axios';
import {BASE_API} from './config';

export default{
    login : async(username,password) => {
        return await axios.post(BASE_API + 'user/login',{ username , password});
    },
    create : async(userInfo) => {
        return await axios.post(BASE_API + 'user/create',userInfo);
    },
    update : async(userInfo) => {
        return await axios.put(BASE_API + 'user/update',userInfo);
    },
    changePassword : async(userInfo) => {
        return await axios.put(BASE_API + 'user/changepassword',userInfo);
    },
    checkToken : async() => {
        const res = await axios.get(BASE_API + 'user/checktoken');
        return res;
    },

    listUsers : async() => {
        const res = await axios.get(BASE_API + 'user/listusers');
        return res;
    },
    getUser : async(id) => {
        const res = await axios.get(BASE_API + 'user/getuser/'+ id);
        return res;
    },
   
}
