import React, { lazy } from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';

const AddCustomer = lazy(() => import('../../pages/customers/AddCustomer'));
const ContactGrid = lazy(() => import('../../pages/customers/ContactGrid'));

const PagesRoute = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route path={`${path}/add-customer`} component={AddCustomer} />
      <Route path={`${path}/edit-customer/:id`} component={AddCustomer} />
      <Route path={`${path}`} component={ContactGrid} />
    </Switch>
  );
};

export default PagesRoute;
