import Cookies from 'js-cookie';
import actions from './actions';

const { loginBegin, loginSuccess, loginErr, logoutBegin, logoutSuccess, logoutErr } = actions;

const login = (e) => {
  if(e != null && typeof(e) != 'undefined' && e.length > 2){
    return async dispatch => {
      try {
        dispatch(loginErr(e));
      } catch (err) {
      }
    };
  }else{

    return async dispatch => {
      try {
        dispatch(loginErr(null));
        dispatch(logoutSuccess(null));
      } catch (err) {
      }
    };
  }
};


const logOut = () => {
  return async dispatch => {
    try {
      Cookies.remove('logedIn');
      Cookies.remove('token');
      Cookies.remove('user_type');
      Cookies.remove('user_id');
      
      dispatch(logoutBegin());
      dispatch(logoutSuccess(null));
    } catch (err) {
      dispatch(logoutErr(err));
    }
  };
};

export { login, logOut };
